import React from 'react'

const AreaSelect = React.lazy(() => import('~/pages/AreaSelect'))
const CardAdd = React.lazy(() => import('~/pages/CardAdd'))
const FinishOrder = React.lazy(() => import('~/pages/Finish'))
const PaymentMethod = React.lazy(() => import('~/pages/PaymentMethod'))
const PlanSelect = React.lazy(() => import('~/pages/PlanSelect'))
const Review = React.lazy(() => import('~/pages/Review'))

const route = [
  {
    path: '/areas',
    element: AreaSelect,
  },
  {
    path: '/plans',
    element: PlanSelect,
  },
  {
    path: '/payment-method',
    element: PaymentMethod,
  },
  {
    path: '/recharge',
    element: CardAdd,
  },
  {
    path: '/review',
    element: Review,
  },
  {
    path: '/finishOrder',
    element: FinishOrder,
  },
]

export default route
