import React from 'react'

const Extract = React.lazy(() => import('~/pages/Extract'))

const route = [
  {
    path: '/balance',
    element: Extract,
  },
]

export default route
