import React from 'react'

import { CircularProgress } from '@material-ui/core'

import LoadingStyled from './styled'

const Loading = () => (
  <LoadingStyled>
    <CircularProgress color="inherit" />
  </LoadingStyled>
)

export default Loading
