import Api from './api'

const path = '/card'

const card = {
  getBalance() {
    return Api.request(`${path}/balance`)
  },

  getPlans(data) {
    return Api.request(`${path}/plans`, { data, method: 'POST' })
  },

  getAreas() {
    return Api.request(`${path}/areas`)
  },
}

export default card
