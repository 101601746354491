import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { Navbar } from '~/components'

import { Container, Header } from './styles'

function Page({ children, title, navbar }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    document.title = `Ubertrans | ${title}`
  }, [title])

  return (
    <div style={{ display: 'flex' }}>
      <Container open={open}>
        <Navbar title={title} isOpen={setOpen}>
          {navbar}
        </Navbar>
        <Header />
        {children}
      </Container>
    </div>
  )
}

Page.defaultProps = {
  navbar: '',
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  navbar: PropTypes.node,
  title: PropTypes.string.isRequired,
}

export default Page
