import React from 'react'

import PropTypes from 'prop-types'

import { PageContentStyled } from './styles'

function PageContent({ children }) {
  return (
    <PageContentStyled container spacing={0}>
      {children}
    </PageContentStyled>
  )
}

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageContent
