import React from 'react'

const List = React.lazy(() => import('~/pages/Orders/List'))
const Detail = React.lazy(() => import('~/pages/Orders/Detail'))

const route = [
  {
    path: '/order',
    element: List,
  },
  {
    path: '/order/:idOrder',
    element: Detail,
  },
]

export default route
