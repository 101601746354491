import React from 'react'

const ForgotPassword = React.lazy(() => import('~/pages/ForgotPassword'))
const ChangePassword = React.lazy(() => import('~/pages/ForgotPassword/ChangePassword'))

const route = [
  {
    path: '/forgot-password',
    element: ForgotPassword,
  },
  {
    path: '/forgot-password/:token',
    element: ChangePassword,
  },
]

export default route
