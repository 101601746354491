import React from 'react'

import {
  Home,
  AccountBalanceWallet,
  ViewList,
  AttachMoney,
  AccountCircle,
} from '@material-ui/icons'

const Menus = [
  {
    title: 'Home',
    icon: <Home />,
    url: '/',
  },
  {
    title: 'Recarga',
    icon: <AttachMoney />,
    url: '/areas',
  },
  {
    title: 'Extrato',
    icon: <AccountBalanceWallet />,
    url: '/balance',
  },
  {
    title: 'Pedidos',
    icon: <ViewList />,
    url: '/order',
  },
  {
    title: 'Minha Conta',
    icon: <AccountCircle />,
    url: '/my-account',
  },
]

export default Menus
