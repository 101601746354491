import React from 'react'

const MyAccount = React.lazy(() => import('~/pages/MyAccount'))
const ChangePasswordWithOldPassword = React.lazy(() => import('~/pages/MyAccount/ChangePasswordWithOldPassword'))
const ChangeUserData = React.lazy(() => import('~/pages/MyAccount/ChangeUserData'))

const route = [
  {
    path: '/my-account',
    element: MyAccount,
  },
  {
    path: '/my-account/change-password',
    element: ChangePasswordWithOldPassword,
  },
  {
    path: '/my-account/my-data',
    element: ChangeUserData,
  },
]

export default route
