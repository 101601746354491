import axios from 'axios'

import loading from '~/commons/utils/loading'
import { getToken, isAuthenticated } from '~/services/auth'

const authData = () => {
  if (isAuthenticated()) {
    const auth = getToken()

    return {
      Authorization: `${auth.type} ${auth.token}`,
    }
  }

  return {}
}

const getConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Accept-Language': 'pt-BR',
    'Content-Type': 'application/json',
    ...authData(),
  },
  paramsSerializer(params) {
    return JSON.stringify(params, { arrayFormat: 'repeat' })
  },
  timeout: 35000,
})

const closeLoading = (loadingId) => {
  if (loadingId) {
    loading.close(loadingId)
  }
}

const api = (
  baseURL,
  config,
  {
    validateRequest = (configApi) => configApi,
    onResponseError = (error) => error,
  }
) => {
  const axiosApi = axios.create({
    mode: 'cors',
    baseURL,
    ...config,
  })

  axiosApi.request = (path, options, showLoading = true) => {
    const loadingId = showLoading ? loading.open() : undefined
    return axiosApi(path, { ...getConfig(), ...options })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        closeLoading(loadingId)
      })
  }

  axiosApi.interceptors.response.use(null, onResponseError)

  return axiosApi
}

export default api
