import React from 'react'

const HowItWorks = React.lazy(() => import('~/pages/HowItWorks'))
const Login = React.lazy(() => import('~/pages/Login'))

const route = [
  {
    path: '/login',
    element: Login,
  },
  {
    path: '/how-it-works',
    element: HowItWorks,
  },
]

export default route
