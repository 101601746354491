import Api from './api'

const path = '/order'

const order = {
  store(data) {
    return Api.request(`${path}`, { data, method: 'POST' })
  },
  calculate(data) {
    return Api.request(`${path}/calculate`, { data, method: 'POST' })
  },
  getAll(page = 1) {
    return Api.request(`${path}?page=${page}`)
  },
  show(id) {
    return Api.request(`${path}/${id}`)
  },
  paymentMethods() {
    return Api.request(`${path}/paymentMethods`)
  },
}

export default order
