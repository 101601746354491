import React from 'react'

import PropTypes from 'prop-types'

import { PageFooterStyled } from './styles'

function PageFooter({ children, position }) {
  return <PageFooterStyled position={position}>{children}</PageFooterStyled>
}

PageFooter.defaultProps = {
  children: null,
  position: 'fixed',
}

PageFooter.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string,
}

export default PageFooter
