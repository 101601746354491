const key = '@UBERTRANS/token'
const keyCardNumber = '@UBERTRANS/cardNumber'

const isAuthenticated = () => !!window.localStorage.getItem(key)

const setToken = async (token) =>
  window.localStorage.setItem(key, JSON.stringify(token))

const setCardNumber = async (cardNumber) =>
  window.localStorage.setItem(keyCardNumber, cardNumber)

const getToken = () => JSON.parse(window.localStorage.getItem(key))

const getCardNumber = () => window.localStorage.getItem(keyCardNumber)

const removeToken = async () => {
  await window.localStorage.removeItem(key)
  window.location.href = '/login'
}

export {
  isAuthenticated,
  getToken,
  setToken,
  removeToken,
  setCardNumber,
  getCardNumber,
}
