import React, { Suspense } from 'react'
import { Provider } from 'react-redux'

import themes from '~/commons/constants/themes'
import { Loading } from '~/components'
import Ubertrans from '~/components/Ubertrans'
import Routes from '~/routes'
import '~/config/reactotron/'
import store from '~/store'

const { REACT_APP_MAINTENANCE } = process.env

const Maintenance = React.lazy(() => import('~/pages/Maintenance'))

function App() {
  const isMaintenance = REACT_APP_MAINTENANCE === 'true'

  return (
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <Ubertrans theme={themes.custom}>
          {!isMaintenance ? <Routes /> : <Maintenance />}
        </Ubertrans>
      </Suspense>
    </Provider>
  )
}

export default App
