import Api from './api'

const path = '/extract'

const extract = {
  get(interval = 2) {
    return Api.request(`${path}?interval=${interval}`)
  },
}

export default extract
