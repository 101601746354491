import React from 'react'

const ServerDownPage = React.lazy(() => import('~/pages/ServerDown'))

const route = [
  {
    path: '/maintenance',
    element: ServerDownPage,
  },
]

export default route
