import Api from './api'

const users = {
  me() {
    return Api.request('/me')
  },
  changeMyPassword(data) {
    return Api.request('/me/changePassword', { data, method: 'PUT' })
  },
  changeData(data) {
    return Api.request('/users', { data, method: 'PUT' })
  },
}

export default users
