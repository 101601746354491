import React from 'react'

const Home = React.lazy(() => import('~/pages/Home'))

const route = [
  {
    path: '/',
    element: Home
  }
]

export default route
