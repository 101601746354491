import toast from '~/commons/utils/toast'
import { removeToken } from '~/services/auth'

const STATUS = {
  400: 'BAD_REQUEST',
  401: 'UNAUTHENTICATED',
  403: 'FORBIDDEN',
  404: 'NOT_FOUND',
  412: 'PRE_CONDITION_FAILED',
  422: 'UNPROCESSABLE_ENTITY',
  424: 'FAILED_DEPENDENCY',
  500: 'FATAL_ERROR',
}

const errors = {
  UNAUTHENTICATED: () => {
    if (window.location.pathname !== '/login') {
      removeToken()
    }
  },
  FATAL_ERROR: () =>
    toast.error('Ocorreu um erro no servidor', { toastId: 'FATAL_ERROR' }),
  NOT_FOUND: () => {},
  BAD_REQUEST: () => {},
  PRE_CONDITION_FAILED: () => {},
  UNPROCESSABLE_ENTITY: () => {},
  FAILED_DEPENDENCY: () => {
    toast.error(
      'Estamos com problemas técnicos nesse momento. Tente mais tarde',
      { toastId: 'FAILED_DEPENDENCY' }
    )
  },
  FORBIDDEN: () =>
    toast.warn('Operação não permitida', { toastId: 'FORBIDDEN' }),
  TIMEOUT: () =>
    toast.error('Ocorreu um erro no servidor', { toastId: 'TIMEOUT' }),
}

const buildError = ({ response }) => {
  if (response?.status !== undefined) {
    const status = STATUS[response.status]
    return errors[status]()
  }

  return errors.FATAL_ERROR()
}

const onResponseError = (error) => {
  buildError(error)
  return Promise.reject(error)
}

const validateRequest = (config) => config

export { onResponseError, validateRequest }
