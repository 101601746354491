import Api from './api'

const Auth = {
  login(data) {
    return Api.request(`/auth`, { data, method: 'POST' })
  },

  logout(data) {
    return Api.request(`/logout`, { data, method: 'DELETE' })
  },
  forgotPassword(data) {
    return Api.request(`/rememberPassword`, { data, method: 'POST' })
  },
  changePasswordByToken(token, data) {
    return Api.request(`/changePassword/${token}`, { data, method: 'POST' })
  },
}

export default Auth
