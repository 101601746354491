import styled from 'styled-components'

export const PageFooterStyled = styled.div`
  ${({ theme, position }) => `
    position: ${position};
    bottom: 0;
    left: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: ${theme.spacing(2, 3)}
  `}
`
