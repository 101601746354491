import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#39a520',
    },
    status: {
      primary: '#3498db',
      success: '#27ae60',
      danger: '#c0392b',
      warning: '#f39c12',
    },
    background: {
      default: '#EFF0F7',
    },
  },
  shape: {
    drawerWidth: 240,
  },
  overrides: {
    MuiPaper: {
      rounded: { borderRadius: '8px' },
      elevation0: { boxShadow: '0px 32px 64px rgba(17, 17, 17, 0.0)' },
      elevation1: { boxShadow: '0px 32px 64px rgba(17, 17, 17, 0.08)' },
      elevation2: { boxShadow: '0px 32px 64px rgba(17, 17, 17, 0.16)' },
      elevation3: { boxShadow: '0px 32px 64px rgba(17, 17, 17, 0.24)' },
      elevation4: { boxShadow: '0px 32px 64px rgba(17, 17, 17, 0.32)' },
    },
    MuiButton: {
      root: {
        borderRadius: '40px',
      },
    },
  },
})

export default theme
