import React, { useState, useEffect } from 'react'

import { Toolbar, IconButton, Typography } from '@material-ui/core'
import { Menu as MenuIcon, Person as PersonIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'

import { Sidebar } from '~/components'
import { usersApi } from '~/services/ubertrans-api'

import { Container, AppBarStyled } from './styles'

function Navbar({ title, children, isOpen }) {
  const [openSidebar, setOpenSidebar] = useState(false)
  const [username, setUsername] = useState(null)

  useEffect(() => {
    usersApi.me().then((response) => setUsername(response.name))
  }, [])

  isOpen(openSidebar)

  return (
    <Container>
      <AppBarStyled position="fixed" open={openSidebar} elevation={0}>
        <Toolbar>
          <>
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              onClick={() => setOpenSidebar(!openSidebar)}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            {!title || (
              <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
                {title}
              </Typography>
            )}
            {children}
            {!username || (
              <Typography
                variant="body2"
                noWrap
                style={{
                  flexGrow: 1,
                  textAlign: 'end',
                }}
              >
                <PersonIcon style={{ verticalAlign: 'bottom' }} />{' '}
                {username.split(' ').slice(0, 2).join(' ')}
              </Typography>
            )}
          </>
        </Toolbar>
      </AppBarStyled>
      <Sidebar open={openSidebar} />
    </Container>
  )
}

Navbar.defaultProps = {
  title: '',
  children: '',
}

Navbar.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.func.isRequired,
}

export default Navbar
