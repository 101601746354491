import React from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'

import { PrivateRoutes, NotFound } from '~/components'

import privateRoutes from './private'
import publicRoutes from './public'

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {publicRoutes.map(({ path, element, exact, ...rest }) => (
          <Route key={path} path={path} component={element} exact {...rest} />
        ))}
        {privateRoutes.map(({ path, element, exact, ...rest }) => (
          <PrivateRoutes
            key={path}
            path={path}
            component={element}
            exact
            {...rest}
          />
        ))}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
